import { Action } from 'vuex'
import axios, { CancelTokenSource } from 'axios'
import { RootState } from '@/store/types'
import { ArticleState } from '../types'
import { Field } from '@/types/action'
import { Platform } from '@/types/platform'
import SiriusAPI from '@/api'

let cancelTokenSource: CancelTokenSource | null = null

const fetchArticleFields: Action<ArticleState, RootState> = async (
  { commit, rootState },
  projectId
) => {
  if (rootState.platform === Platform.im && !projectId) return

  cancelTokenSource?.cancel()

  cancelTokenSource = axios.CancelToken.source()

  try {
    const type = await SiriusAPI.article.fetchArticleMeta(projectId)

    const types = type.reduce<Record<string, Field>>((acc, item) => {
      acc[item.caption] = item
      acc[item.value] = item

      return acc
    }, {})

    commit('setArticleField', { key: 'types', value: types })
  } catch (error) {
    if (!axios.isCancel(error)) {
      commit('setArticleField', { key: 'types', value: {} })
    }
  }
}

export default fetchArticleFields
